<template>
  <div class="page">
    <div class="bg">
      <img class="bg-img" :src="info.detail.banner" />
    </div>
    <div class="tl">
      <div class="item">
        <img :src="info.detail.logo" class="logoUrl" />
        <div class="cont">
          <div class="cont_box">
            <div class="cnName">
              {{ info.detail.name }}
            </div>
            <div class="mt3">
              <span
                class="feature"
                v-for="(feat, idx) in info.detail.features"
                :key="idx"
              >
                {{ feat }}
              </span>
            </div>
          </div>
          <div class="collegeDesc">
            {{ info.detail.cityName }} /
            <span v-for="(cate, idx1) in info.detail.categories" :key="idx1">
              {{ cate }}
            </span>
            / {{ info.detail.natureType }} / {{ info.detail.belong }}
          </div>
        </div>
      </div>
      <div class="jian">
        <div class="t" :class="all == false ? 'ellipsis2' : ''">
          简介：{{ info.detail.introduction }}
        </div>
        <div class="b" @click="all = true" v-if="!all">
          全部
        </div>
        <div class="b" @click="all = false" v-else>
          收起
        </div>
      </div>
    </div>

    <div class="tl-2">
      <!-- 			<div class="td">
				<div class="no">
				</div>
				<div class="tt">
					基本信息
				</div>
			</div> -->
      <div class="info">
        <div class="box-info">
          <img src="../../assets/image/findschool/icon-banxueleixing@2x.png" />
          <div class="r">
            <div class="t">
              办学性质
            </div>
            <div class="b28">
              {{ info.banxueXingzhi }}
            </div>
          </div>
        </div>
        <div class="box-info">
          <img src="../../assets/image/findschool/icon-chengshi@2x.png" />
          <div class="r">
            <div class="t">
              省份城市
            </div>
            <div class="b28">
              {{ info.city }}
            </div>
          </div>
        </div>
        <div class="box-info">
          <img src="../../assets/image/findschool/icon-lishuyu@2x.png" />
          <div class="r">
            <div class="t">
              隶属关系
            </div>
            <div class="b28">
              {{ info.liShuYu }}
            </div>
          </div>
        </div>
        <div class="box-info">
          <img src="../../assets/image/findschool/cengci.png" />
          <div class="r">
            <div class="t">
              学历层次
            </div>
            <div class="b">
              {{ info.xlCengCi }}
            </div>
          </div>
        </div>
        <div class="box-info">
          <img
            src="../../assets/image/findschool/icon-banxueleixing_1@2x.png"
          />
          <div class="r">
            <div class="t">
              办学类型
            </div>
            <div class="b28">
              {{ info.banxueType }}
            </div>
          </div>
        </div>
        <div class="box-info">
          <img
            src="../../assets/image/findschool/icon-jianxiaoshijian@2x.png"
          />
          <div class="r">
            <div class="t">
              建校时间
            </div>
            <div class="b">
              {{ info.years }}
              <text>年</text>
            </div>
          </div>
        </div>
        <!-- <div class="box-info">
					<img src="../../static/findschool/icon-yuanxiaoleixing@2x.png" />
					<div class="r">
						<div class="t">
							院校类型
						</div>
						<div class="b28">
							{{info.schoolType}}
						</div>
					</div>
				</div> -->
        <div class="box-info">
          <img src="../../assets/image/findschool/icon-shuoshidian@2x.png" />
          <div class="r">
            <div class="t">
              硕士点
            </div>
            <div class="b">
              {{ info.suoShiDian }}
              <text>个</text>
            </div>
          </div>
        </div>
        <div class="box-info">
          <img src="../../assets/image/findschool/icon-boshidian@2x.png" />
          <div class="r">
            <div class="t">
              博士点
            </div>
            <div class="b">
              {{ info.boShiDian }}
              <text>个</text>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tl-2">
      <!-- 			<div class="td">
						<div class="no">
						</div>
						<div class="tt">
							校园风光
						</div>
					</div> -->
      <div class="img">
        <scroll-div scroll-x class="row">
          <div
            class="box"
            v-for="(item, index) in info.schoolImgs"
            :key="index"
            @click="lookImg(info.schoolImgs, index)"
          >
            <img :src="item.imgUrl" mode="" />
            <div class="b">
              {{ item.imgType }}
            </div>
          </div>
        </scroll-div>
      </div>
    </div>
    <div class="tl-2" v-if="info.zszcList && info.zszcList.length > 0">
      <div class="td sb">
        <div class="flex-align">
          <div class="no"></div>
          <div class="tt">
            招生简章
          </div>
        </div>
        <div
          class="more"
          @click="
            goPage(
              `/pages/findSchool/recruitList?id=${id}&unityCode=${unityCode}`
            )
          "
        >
          更多
        </div>
      </div>
      <div class="list">
        <div
          class="line"
          v-for="(item, index) in info.zszcList"
          :key="index"
          @click="goPage(`/pages/findSchool/recruitDetail?id=${item.id}`)"
        >
          <div class="">
            {{ item.title }}
          </div>
          <img src="../../assets/image/colleges/icon_benke@2x.png" mode="" />
        </div>
      </div>
    </div>
    <div class="tl-2">
      <div class="td">
        <div class="no"></div>
        <div class="tt">
          院校排名
        </div>
        <!-- <img src="/static/index/icon-yuanxiaopk@3x.png" class="pkimg"/> -->
      </div>
      <div class="img" v-if="info.rankData && info.rankData.length > 0">
        <scroll-div scroll-x class="row">
          <div
            class="box-ph"
            v-for="(item, index) in info.rankData"
            :key="index"
          >
            <div class="val">
              {{ item.collegeRankingYears[0].value }}
            </div>
            <div class="num">
              {{ item.type }}
            </div>
            <div class="name">
              {{ item.collegeRankingYears[0].year }}
            </div>
          </div>
        </scroll-div>
      </div>
      <div class="zwsj" v-else>
        暂无数据
      </div>
    </div>

    <div class="tl-2">
      <div class="td sb">
        <div class="flex-align">
          <div class="no"></div>
          <div class="tt">
            学科评估
          </div>
        </div>
        <div class="more" @click="handleSubject">
          {{ subjectVal }}
        </div>
      </div>
      <div
        class="list"
        v-if="info.subject_evaluate && info.subject_evaluate.length > 0"
      >
        <div
          class="line-zd"
          v-for="(item, index) in subjectData.data"
          :key="index"
        >
          <div class="l">
            {{ item.name }}
          </div>
          <div>
            {{ item.level }}
          </div>
        </div>
      </div>
      <div class="zwsj" v-else>
        暂无数据
      </div>
    </div>
    <div
      class="tl-2"
      v-if="
        (tszyList.gjts && tszyList.gjts.length > 0) ||
          (tszyList.sjts && tszyList.sjts.length > 0)
      "
    >
      <div class="td sb">
        <div class="flex-align">
          <div class="no"></div>
          <div class="tt">
            特色专业
          </div>
        </div>
      </div>
      <div class="list">
        <block v-if="tszyList.gjts && tszyList.gjts.length > 0">
          <div class="ts-title">
            国家级特色专业
          </div>
          <div class="ts-tab">
            <div v-for="(item, i) in tszyList.gjts" :key="i">
              {{ item.zyName }}
            </div>
          </div>
        </block>
        <block v-if="tszyList.sjts && tszyList.sjts.length > 0">
          <div class="ts-title">
            省级特色专业
          </div>
          <div class="ts-tab">
            <div v-for="(item, i) in tszyList.sjts" :key="i">
              {{ item.zyName }}
            </div>
          </div>
        </block>
      </div>
    </div>
    <div class="tl-2">
      <div class="td sb">
        <div class="flex-align">
          <div class="no"></div>
          <div class="tt">
            院校开设专业
          </div>
        </div>
        <div
          class="more"
          @click="goPage(`specialtyList?code=${unityCode}&type=sbzd`)"
        >
          <!-- 更多 -->
        </div>
      </div>
      <div class="list">
        <div
          class="line-zd" style="display:block;"
        >
          <div class="l">
            <div class="t">
              层次：国家级
            </div>
            <div
              class="b"
              v-for="ele in info.profession.countries"
              :key="ele.code"
              @click="
                goPage(
                  `/pages/findSchool/specialtyDetail?id=${item.id}&unityCode=${ele.code}`
                )
              "
            >
              <p>{{ ele.name }}</p>
            </div>
          </div>
          <div class="l">
            <div class="t">
              层次：省重点
            </div>
            <div
              class="b"
              v-for="ele in info.profession.provincePoints"
              :key="ele.code"
              @click="
                goPage(
                  `/pages/findSchool/specialtyDetail?id=${item.id}&unityCode=${ele.code}`
                )
              "
            >
              <p>{{ ele.name }}</p>
            </div>
          </div>
          <div class="l">
            <div class="t">
              层次：省级
            </div>
            <div
              class="b"
              v-for="ele in info.profession.countries"
              :key="ele.code"
              @click="
                goPage(
                  `/pages/findSchool/specialtyDetail?id=${item.id}&unityCode=${ele.code}`
                )
              "
            >
              <p>{{ ele.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tl-2">
      <div class="td sb">
        <div class="flex-align">
          <div class="no"></div>
          <div class="tt">
            就业流向
          </div>
        </div>
        <!-- 				<div class="more" @click="goPage(`specialtyList?id=${id}&type=gjts`)">
					更多
				</div> -->
      </div>
      <div class="warn">
        <div class="warn-info" v-if="info.employmentreport && info.employmentreport.length > 0">
          <div class="warn-line" v-for="(item, index) in info.employmentreport" :key="index">
            <div class="t">
             <a :href="item.webSite">
                {{ item.title }}
             </a>
            </div>
            <div class="b">
              <div class="l">
                <u-line-progress
                  active-color="#910000"
                  :percent="parseFloat(item.scale)"
                  height="10"
                  :show-percent="false"
                  :striped="true"
                  :striped-active="true"
                ></u-line-progress>
              </div>
              <div class="r">
                {{ item.scale }}
              </div>
            </div>
          </div>
        </div>
        <div class="zwsj" v-else>
          暂无数据
        </div>
      </div>
    </div>
    

    <!-- 弹窗 -->
    <van-popup
      v-model="subjectPopupShow"
      closeable
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div class="popup_box">
        <div
          class="line-zd"
          v-for="(item, index) in info.subject_evaluate"
          :key="index"
          @click="(subjectVal = item.typeName), (subjectPopupShow = false)"
        >
          {{ item.typeName }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      all: false, // 展开简介
      show: false, // 弹窗
      url: '',
      id: '', // 学校id
      unityCode: '',
      info: {}, // 信息
      content: '',
      xkpgList: [],
      kszyList: [],
      tszyList: [],
      jylxList: [],
      mydList: [],
      collectType: null,
      showAll: false,
      subjectVal: '',
      subjectPopupShow: false,
      subjectData: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    handleSubject() {
      this.subjectPopupShow = true;
      // this.subjectData=this.info.subject_evaluate[0].data
    },
    // 获取大学详情
    async getDetail() {
      const {
        currentRoute: { query },
      } = this.$router;
      const data = {
        collegeCode: query.id,
      };
      let apiUrl = `/php/college.detail?collegeCode=${query.id}`;
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);
        if (res.data.ret == 0) {
          this.info = res.data.data;
          this.subjectVal = res.data.data.subject_evaluate[0].typeName;
          this.subjectData = this.info.subject_evaluate[0];
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background: #fff;
  padding-bottom: 20px;
}
.introduceContent {
  position: relative;
  padding: 30px;
  overflow: hidden;
}
.popup_box {
  text-align: center;
  padding: 30px 0;
  .line-zd {
    padding: 9px 0;
    border-bottom: 1px solid #ddd;
  }
}
.bg {
  width: 100%;
  position: relative;
  // display: flex;
  // justify-content: space-between;
  // padding: 80px 30px 0;
  .bg-img {
    width: 100%;
  }
}

.tl {
  background: #fff;

  div.item {
    display: flex;
    align-items: center;
    width: 100%;
    background: #fff;
    // justify-content: space-between;
    font-size: 14px;
    padding: 10px 15px;
    .logoUrl {
      width: 72px;
    }
    .cont {
      color: #666;
      padding-left: 18px;
      .cont_box {
      }
      .cnName {
        font-size: 16px;
        color: #222;
        margin-right: 10px;
        font-weight: 500;
      }
      .mt3 {
        text-align: left;
        margin: 5px 0;
      }
      .feature {
        height: 14px;
        line-height: 1.2;
        padding: 1px 3px;
        background: #fff;
        color: #333;
        font-size: 12px;
        border: 1px solid #ccc;
        border-radius: 4px 4px 4px 4px;
        margin-right: 5px;
      }
      .collegeDesc {
        font-size: 12px;
        font-weight: 400;
        color: #333;
      }
    }
  }

  .jian {
    margin-top: 10px;
    padding: 0 15px;
    position: relative;

    .t {
      font-size: 14px;
    }
    .ellipsis2 {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .b {
      width: 100%;
      text-align: right;
      color: #910000 !important;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.sb {
  justify-content: space-between;
  // padding-right: 30px;
}
.img {
  .row {
    white-space: nowrap;
    // margin-top: 30rpx;

    .box {
      display: inline-block;
      width: 216rpx;
      margin-right: 25rpx;

      // height: 142rpx;
      image {
        width: 216rpx;
        height: 142rpx;
        border-radius: 10rpx;
      }

      .b {
        text-align: center;
        // margin-top: 20rpx;
        font-size: 24rpx;
      }
    }

    .box-ph {
      display: inline-flex;
      width: 60px;
      text-align: center;
      height: 83px;
      border-radius: 8px;
      color: #333;
      background: #faf8f8;
      margin-right: 10px;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;

      .num {
        text-align: center;
        // margin-top: 20rpx;
        // line-height: 70rpx;
        font-size: 12px;
        color: #333;
        // font-weight: bold;
        display: block;
        width: 100%;
      }

      .name {
        font-size: 12px;
        text-align: center;
        // border-top: 1px dotted #910000;
        // padding-top: 0px;
        display: block;
        width: 100%;
      }
      .val {
        color: #910000;
        font-size: 18px;
        font-weight: bold;
        display: block;
        width: 100%;
      }
    }
  }
}

.tl-2 {
  background: #fff;
  padding: 12px 15px;

  .td {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 12px;

    .flex-align {
      display: flex;
      align-items: center;
    }

    .no {
      width: 4px;
      height: 15px;
      background-color: #910000;
      margin-right: 8px;
    }

    .tt {
      font-size: 14px;
      font-weight: bold;
    }

    .more {
      color: #910000;
      font-size: 14px;
    }

    .pkimg {
      width: 114px;
      height: 114px;
      position: absolute;
      right: 20px;
      top: -50px;
    }
  }
  .info {
    // margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    display: none;

    .box-info {
      width: 33%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 100px;
      }

      .t {
        font-size: 14px;
      }

      .b {
        font-size: 30px;

        p {
          font-size: 20px;
        }
      }

      .b28 {
        font-size: 28px;
      }
    }
  }

  .list {
    // padding-right: 30px;

    .line {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eee;
      // line-height: 70px;
      padding: 15px 0;

      image {
        width: 10px;
        height: 17px;
      }
    }

    .line-zd {
      // margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #eee;
      // line-height: 70px;
      line-height: 20px;

      .l {
        font-size: 14px;
        font-weight: 700;
        .t {
          font-size: 12px;
          font-weight: 700;
          margin-bottom: 10px;
          // line-height: 60px;
        }

        .b {
          display: inline-flex;
          p {
            padding: 6px 16px;
            background: #fef6ec;
            border-radius: 5px;
            color: #910000;
            font-size: 12px;
            margin:0 8px 8px 0;
          }
        }
      }

      image {
        width: 10px;
        height: 17px;
      }
    }

    .ts-title {
      font-size: 32px;
      color: #666666;
      margin-top: 20px;
    }

    .ts-tab {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      view {
        padding: 10px 20px;
        margin-right: 20px;
        margin-top: 30px;
        background: #ffffff;
        border: 1px solid #910000;
        border-radius: 24px;
      }
    }
  }

  .warn {
    .warn-info {
      margin-top: 30px;

      .warn-line {
        line-height: 50px;

        .t {
          p {
            margin-left: 40px;
            color: #666666;
            font-size: 24px;
          }
        }

        .b {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .l {
            width: 83%;
            display: flex;
            align-items: center;

            .num {
              margin-left: 15px;
              font-size: 24px;
            }
          }

          .r {
            font-size: 24px;
          }
        }
      }
    }
  }
}

.b-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  background: #fff;
  box-shadow: 0px -2px 8px 2px rgba(0, 0, 0, 0.16);
  font-size: 30px;

  .l {
    display: flex;
    text-align: center;
    line-height: 50px;

    .sc {
      width: 130px;
      line-height: 90px;
      border: 1px solid #eeeeee;
      border-radius: 10px;
    }

    .db {
      margin-left: 16px;
      width: 130px;
      background: #910000;
      border-radius: 10px;
      color: #fff;
      line-height: 90px;
    }
  }

  .r {
    display: flex;
    line-height: 90px;
    text-align: center;

    .zs {
      width: 190px;

      background: #ffffff;
      border: 1px solid #910000;
      border-radius: 10px 0 0 10px;
    }

    .fs {
      width: 190px;
      background: #910000;
      border-radius: 0 10px 10px 0;
      color: #fff;
    }
  }
}

.pop {
  width: 600px;
  padding: 25px;
  .add {
    font-size: 24px;
    line-height: 40px;
    margin-top: 20px;
  }
}
.zwsj {
  text-align: center;
  background: #f5f5f5;
  color: #666666;
  line-height: 60px;
  border-radius: 10px;
}
</style>
